import React, { useState, useEffect } from 'react';
import SecurityIssueCard from "../../components/securityissue-card/SecurityIssueCard";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import { ReactComponent as HighRiskGroup } from "../../assets/images/HighRiskGroup.svg";
import { ReactComponent as MediumGroupRisk } from "../../assets/images/MediumGroupRisk.svg";
import { ReactComponent as LowRiskGroup } from "../../assets/images/LowRiskGroup.svg";
import dotMenuIcon from "../../assets/images/dotMenuIcon.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ActionPopover from "../../components/actions/ActionPopover";
import { ReactComponent as Elipses } from "../../assets/images/elipses.svg";

const GroupIssuesTitle = ({ group, collapsedGroups, toggleCollapse, cvssRisk, cvssScore, actions, onSelectionChange, clearSelectedRows ,handleActionClick,type}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const isCollapsed = collapsedGroups[group.issue_name];
  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    if (newCheckedState) {
      onSelectionChange({ groupInfo: group.issue_name, armory_id: group.armory_id, assets: group.assets }, true);
    } else {
      onSelectionChange({ groupInfo: group.issue_name, armory_id: group.armory_id, assets: group.assets }, false);
    }
  };

  const handlePopoverToggle = (groupId) => {
    setActivePopover(activePopover === groupId ? null : groupId);
  };
    const handleActionSelect = (id,action) => {
    handleActionClick(action.name, group,1); 
    setActivePopover(null);  
  };
  const actionPopover = (groupId) => (
    <Popover id={`action-group-${groupId}`} className="p-0">
      <Popover.Body className="p-0">
        <ActionPopover
          rowId={groupId}
          actions={actions}
          onApply={handleActionSelect}
        />
      </Popover.Body>
    </Popover>
  );
  useEffect(() => {

    setIsChecked(false);

  }, [clearSelectedRows]);

  return (
    <div className={`d-flex ${collapsedGroups[group.issue_name] ? 'align-items-start ' : 'align-items-center'}`}>
      <div className={`me-1 issue-checkbox ${collapsedGroups[group.issue_name] ? 'mt-1' : ''}`}>
        <input
          type="checkbox"
          className="security-checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}

        />
      </div>
      <div className={`ports-list-table d-block w-100 ${isChecked ? 'border-action' : ''}`} >
        <div
          className="left-ports-container d-flex align-items-baseline"
          onClick={() => toggleCollapse(group.issue_name)}
          style={{ width: collapsedGroups[group.issue_name] ? "auto" : "" }}
        >
          <Arrow
            className={
              collapsedGroups[group.issue_name]
                ? "arrow-rotated-down"
                : "arrow-rotated-up"
            }
          />
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              <span className="tab-issue-title">{group.issue_name}:</span>
              <div className="section_1_part1">
                <div className="securityissueCard_title">
                  {/* Additional group-specific data can go here */}
                </div>
                <div className="d-flex align-items-center">
                  {group?.armory_tested ? (
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                      <img
                        className="h-100"
                        src={armorytestedIconSmall}
                        alt="Armory Tested"
                      />
                      <div className="securityissueCard_subtitle">
                        ARMORY TESTED
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={actionPopover(group.issue_name)}
                    show={activePopover === group.issue_name}
                    onToggle={() => handlePopoverToggle(group.issue_name)}
                    rootClose
                  >
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Elipses className="cves-popup cursor-pointer ms-2" />
                    </div>
                  </OverlayTrigger>
                </div>

              </div>
            </div>
            <div className="d-flex align-items-center mt-1">
              {cvssRisk === "primary" && (
                <div>
                  <HighRiskGroup />
                  <label className="ms-1 group-tab-sub">High Risk,</label>
                </div>
              )}
              {cvssRisk === "secondary" && (
                <div>
                  <MediumGroupRisk />
                  <label className="ms-1 group-tab-sub">Medium Risk,</label>
                </div>
              )}
              {cvssRisk === "tertiary" && (
                <div>
                  <LowRiskGroup />
                  <label className="ms-1 group-tab-sub">Low Risk,</label>
                </div>
              )}
              <div className="d-flex align-items-center mx-2">
                <div className="title-text mt-0">
                  <label className="group-tab-value ms-1">{group?.assets.length}</label>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <label className="group-tab-sub ms-1">Assets ,</label>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="group-tab-value mt-0 pe-1">Category:</div>
                <div className="d-flex align-items-center justify-content-center">
                  <label className="group-tab-sub">{group.scan_category},</label>
                </div>
              </div>
              <div className="d-flex align-items-center ms-1">
                <div className="group-tab-value mt-0 pe-2">CVE:</div>
                <div className={`cve-tag d-flex align-items-center justify-content-center ${cvssRisk}`}>
                  <span>{group.cve ? "Yes" : "No"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="right-ports-container"
          style={{
            width: collapsedGroups[group.issue_name] ? "auto" : "",
          }}
        >
          {collapsedGroups[group.issue_name] && (
            <div className="port-collapse-table group-issues">
              {group.assets.map((el) => (
                <SecurityIssueCard
                  key={el.id}
                  classNameVariant={cvssRisk}
                  RiskPercentage={cvssScore}
                  data={group}
                  asset={el}
                  isGroup={true}
                  actions={actions}
                  handleActionClick={handleActionClick}
                  onSelectionChange={onSelectionChange}
                  type={type}
                  criticalityLevel={el.asset_criticality}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default GroupIssuesTitle;
