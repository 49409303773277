import React, { useState, useRef, useContext } from "react";
import {
  Navbar,
  NavDropdown,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Logo1 } from "../../assets/images/logo1.svg";
import { ReactComponent as Logo2 } from "../../assets/images/logo2.svg";
import { ReactComponent as Bell } from "../../assets/images/icon-bell.svg";
import { ReactComponent as User } from "../../assets/images/user.svg";
import { ReactComponent as Logout } from "../../assets/images/logout.svg";
import { ReactComponent as Text } from "../../assets/images/icon-text.svg";
import { ReactComponent as Stars } from "../../assets/images/icon-stars.svg";
import { ReactComponent as NavArrowDown } from "../../assets/images/nav-down-arrow.svg";
import { ReactComponent as WhatsNew } from "../../assets/images/whats-new-icon.svg";

import PrivacyPolicyModal from "../../components/privacy-policy/PrivacyPolicy";
import TermsConditionsModal from "../../auth/register/TermsConditionsModal";
import { v4 as uuidv4 } from "uuid";
import Popover from "react-bootstrap/Popover";
// import ChangePassword from "../../components/change-password/ChangePassword";
import axios from "../../util/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScannerContext } from "../../components/ScannerContext";
import { showToast } from "../../util/toasts";

const TopBarHeader = ({ onToggle, targetId }) => {
  const { dropdownItems } = useContext(ScannerContext);
  const [showPrivacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  // const [showChangePassword, setChangePasswordModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isToolOpen, setIsToolOpen] = useState(false);
  const formikRef = useRef();
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover1, setShowPopover1] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  const toggleDropdown = (isOpen) => {
    setIsOpen(isOpen);
  };

  const toggleToolDropdown = (isOpen) => {
    setIsToolOpen(isOpen);
  };

  // const handleChangePasswordModal = () => {
  //   setChangePasswordModal(false);
  // };

  const hidePrivacyPolicyModal = () => {
    setPrivacyPolicyModal(false);
  };

  const handleDeclinePrivacy = () => {
    setPrivacyPolicyModal(false);
  };

  const handleAcceptTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", true);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(true);
  };

  const handleDeclineTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", false);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(false);
  };

  const logout = async () => {
    try {
      await axios.get(`/logout`);
      localStorage.clear();
      navigate("/auth/login");
      showToast(
        "You are logged out!",
        "success",
        "top-center",
        "light"
      );
    } catch (error) {
      showToast(
        "Failed to logout",
        "error",
        "top-center",
        "light"
      );
    }
  };

  const getUser = () => {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : {};
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.member_level == 9;

  const tooltip = <Tooltip id={`tooltip-${uuidv4()}`}>Contact Us</Tooltip>;
  const accountTooltip = <Tooltip id={`tooltip-${uuidv4()}`}>Account</Tooltip>;
  const NotificationTooltip = (
    <Tooltip id={`tooltip-${uuidv4()}`}>Notifications</Tooltip>
  );

  const whatsnewTooltip = (
    <Tooltip id={`tooltip-${uuidv4()}`}>What's New</Tooltip>
  );

  const popover = (
    <Popover id="popover-basic-comming">
      <Popover.Body className="comming-tool-body">
        <label className="comming-text-tool">Coming Soon</label>
      </Popover.Body>
    </Popover>
  );

  const isActiveLink = (linkPath) => {
    return pathname === linkPath;
  };

  const isDropdownActive = () => {
    return ["/leaks", "/news", "/spoof", "/urls", "/admin"].includes(pathname);
  };

  const isSettingsDropdownActive = () => {
    return [
      "/change-password",
      "/privacy-policy",
      "/terms-conditions",
      "/logout",
    ].includes(pathname);
  };

  const handleNavClick = (event, linkPath) => {
    if (isActiveLink(linkPath)) {
      event.preventDefault();
      window.location.reload();
    }
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className="border-bottom ps-2 pe-4 nav-main"
        onMouseEnter={() => {
          document.body.style.overflowY = "hidden";

          setShowPopover(true);
        }}
        onMouseLeave={() => {
          setShowPopover(false);
        }}
      >
        <Link
          to={`/?target_id=${targetId}`}
          className="navbar-brand text-white multiLogos"
        >
          <Logo
            className="d-none d-lg-block img-fluid nav-logo-img"
            height="40px"
            width="90px"
          />
          <div className="logoDevider"></div>
          <Logo1
            className="d-none d-lg-block img-fluid nav-logo-img newCompanyLogo"
            height="40px"
            width="90px"
          />
          <Logo2
            className="d-none d-lg-block img-fluid nav-logo-img newCompanyLogo"
            height="40px"
            width="90px"
          />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-3">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="bottom"
              overlay={popover}
              className=" mx-3"
            >
              <div
                className={`hover-none disable-link-color mx-3 ${
                  isActiveLink(`/?target_id=${targetId}`)
                    ? "nav-link-active"
                    : ""
                }`}
                onClick={(e) => {
                  if (dropdownItems?.length > 0) {
                    handleNavClick(e, `/?target_id=${targetId}`);
                  }
                }}
              >
                Dashboard
              </div>
            </OverlayTrigger>
            <Link
              className={`disable-link-color ${
                dropdownItems.length == 0 ? "cursor-none" : "text-white"
              } mx-3 ${
                isActiveLink(`/security-issues/${targetId}`)
                  ? "nav-link-active"
                  : ""
              }`}
              to={dropdownItems?.length > 0 && `/security-issues/${targetId}`}
              onClick={(e) => {
                if (dropdownItems?.length > 0) {
                  handleNavClick(e, `/security-issues/${targetId}`);
                }
              }}
            >
              Security Issues
            </Link>
            <Link
              className={`disable-link-color ${
                dropdownItems.length == 0 ? "cursor-none" : "text-white"
              } mx-3 ${
                isActiveLink(`/assets-inventory/${targetId}`)
                  ? "nav-link-active"
                  : ""
              }`}
              to={dropdownItems?.length > 0 && `/assets-inventory/${targetId}`}
              onClick={(e) => {
                if (dropdownItems?.length > 0) {
                  handleNavClick(e, `/assets-inventory/${targetId}`);
                }
              }}
            >
              Asset Inventory
            </Link>
            <Link
              className={`disable-link-color ${
                dropdownItems.length == 0 ? "cursor-none" : "text-white"
              } mx-3 ${
                isActiveLink(`/offense/${targetId}`) ? "nav-link-active" : ""
              }`}
              to={dropdownItems?.length > 0 && `/offense/${targetId}`}
              onClick={(e) => {
                if (dropdownItems?.length > 0) {
                  handleNavClick(e, `/offense/${targetId}`);
                }
              }}
            >
              Offense
            </Link>
            <Link
              className={`disable-link-color ${
                dropdownItems.length == 0 ? "cursor-none" : "text-white"
              } mx-3 ${
                isActiveLink("/cyber-services") ? "nav-link-active" : ""
              }`}
              to={dropdownItems?.length > 0 && "/cyber-services"}
              onClick={(e) => {
                if (dropdownItems?.length > 0) {
                  handleNavClick(e, "/cyber-services");
                }
              }}
            >
              Cyber Services
            </Link>
            <NavDropdown
              title={
                <span
                  className={`text-${
                    isToolOpen || isActiveLink() ? "active" : "white"
                  } ${
                    isDropdownActive() ? "nav-dropdown-active tools-active" : ""
                  }`}
                >
                  Tools
                  {isToolOpen ? (
                    <NavArrowDown
                      width={25}
                      height={25}
                      className="arrow-up ms-1"
                    />
                  ) : (
                    <NavArrowDown
                      width={25}
                      height={25}
                      className="arrow-down ms-1 "
                    />
                  )}
                </span>
              }
              id="tools-nav-dropdown"
              className={`mx-3 tools ${
                isDropdownActive() ? "tools-active" : ""
              }`}
              show={isToolOpen}
              onToggle={toggleToolDropdown}
            >
              <NavDropdown.Item
                as={Link}
                to="/leaks"
                className={`text-dark ${
                  isActiveLink("/leaks") ? "nav-dropdown-active" : ""
                }`}
              >
                Leaks
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/news"
                className={`text-dark ${
                  isActiveLink("/news") ? "nav-dropdown-active" : ""
                }`}
              >
                AI Powered News
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/spoof"
                className={`text-dark ${
                  isActiveLink("/spoof") ? "nav-dropdown-active" : ""
                }`}
              >
                Spoofing
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/urls"
                className={`text-dark ${
                  isActiveLink("/urls") ? "nav-dropdown-active" : ""
                }`}
              >
                Locator
              </NavDropdown.Item>
              {isAdmin && (
                <NavDropdown.Item
                  as={Link}
                  to="/admin"
                  title="Admin Dashboard"
                  onClick={onToggle}
                  className={`d-flex justify-content-start align-items-start text-dark ${
                    isActiveLink("/admin") ? "nav-link-active" : ""
                  }`}
                >
                  Admin Dashboard
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
          <div className="text-white d-flex align-items-center justify-content-center ">
            <NavDropdown
              title={
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement={"bottom"}
                  overlay={accountTooltip}
                >
                  <span>
                    <User
                      className={` user-icon ${
                        isOpen || isSettingsDropdownActive() ? "open" : ""
                      }`}
                      style={{ marginTop: "-2px" }}
                    />
                  </span>
                </OverlayTrigger>
              }
              id="settings-nav-dropdown"
              className={`d-flex align-items-center justify-content-center mx-1 icon-dropdown ${
                isSettingsDropdownActive() ? "nav-dropdown-active" : ""
              }`}
              align="center"
              show={isOpen}
              onToggle={toggleDropdown}
            >
              <div className="user-dropdown d-flex flex-column align-items-center justify-content-center mt-3">
                <h3 className="mb-2">{user?.fullname}</h3>
                <span
                  style={{
                    overflowWrap: "break-word",
                    wordBreak: "break-all",
                    padding: "0px 6px 5px",
                    textAlign: "center",
                  }}
                >
                  {user?.email}
                </span>
              </div>
              <NavDropdown.Item
                as={Link}
                to="/change-password"
                onClick={() => navigate("/change-password")}
                className={`text-dark `}
              >
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                // to=""
                onClick={() => setPrivacyPolicyModal(true)}
                className={`text-dark `}
              >
                Privacy Policy
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                // to=""
                onClick={() => setShowTermsModal(true)}
                className={`text-dark`}
              >
                Terms & Conditions
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/logout"
                onClick={logout}
                className={`text-dark d-flex align-items-center justify-content-between logout-dropdown ${
                  isActiveLink("/logout") ? "" : ""
                }`}
              >
                Logout <Logout height={15} width={15} />
              </NavDropdown.Item>
            </NavDropdown>
            <div
              className={`icon-main d-flex justify-content-center align-items-center ${
                isActiveLink("/contact") ? "icon-active" : ""
              }`}
            >
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement={"bottom"}
                overlay={tooltip}
              >
                <Text
                  className="icon-pointer"
                  onClick={() => navigate("/contact")}
                />
              </OverlayTrigger>
            </div>
            {/* <div
              className={`icon-main d-flex justify-content-center align-items-center ${
                isActiveLink("/whats-new") ? "icon-active" : ""
              }`}
            >
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement={"bottom"}
                overlay={whatsnewTooltip}
              >
                <WhatsNew
                  className="icon-pointer"
                  onClick={() => navigate("/whats-new")}
                />
              </OverlayTrigger>
            </div> */}
          </div>
        </Navbar.Collapse>
      </Navbar>
      {/* <ChangePassword
        show={showChangePassword}
        onHide={handleChangePasswordModal}
        onDecline={handleChangePasswordModal}
      /> */}
      <PrivacyPolicyModal
        show={showPrivacyPolicyModal}
        onHide={hidePrivacyPolicyModal}
        onDecline={handleDeclinePrivacy}
      />
      <TermsConditionsModal
        show={showTermsModal}
        onHide={handleDeclineTerms}
        onContinue={handleAcceptTerms}
        onDecline={handleDeclineTerms}
        isAfterRegister={true}
      />
    </>
  );
};

export default TopBarHeader;
