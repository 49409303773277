import React, { useEffect, useRef, useState } from "react";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import dotMenuIcon from "../../assets/images/dotMenuIcon.png";
import securityissue_Point from "../../assets/images/securityissue_Point.png";
import CvesPopup from "../cves/CvesPopup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { ReactComponent as FaExclamationCircle } from "../../assets/images/FaExclamationCircle.svg";
import { ReactComponent as InfoTag } from "../../assets/images/infoTag.svg";
import { ReactComponent as OpenCircle } from "../../assets/images/open-circle.svg";
import { ReactComponent as InfoCircle } from "../../assets/images/INFO-CIRCLE.svg";
import { ReactComponent as Acknowledged } from "../../assets/images/Acknowledged.svg";
import { ReactComponent as FalsePositive } from "../../assets/images/False-Positive.svg";
import { ReactComponent as Solved } from "../../assets/images/Solved.svg";
import { ReactComponent as ReOpened } from "../../assets/images/Re-Opened.svg";
import { ReactComponent as PendingValidation } from "../../assets/images/Pending-Validation.svg";
import CriticalityTag from "./CriticalityTag";
import Moment from "react-moment";
import AssetsHost from "./AssetsHost";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ActionPopover from "../../components/actions/ActionPopover";
import { ReactComponent as Elipses } from "../../assets/images/elipses.svg";

const SecurityIssueCard = ({
  classNameVariant = "",
  RiskPercentage,
  data,
  isGroup = false,
  asset,
  actions,
  onSelectionChange,
  clearSelectedRows,
  handleActionClick,
  type,
  criticalityLevel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Reject");
  const [action, setAction] = useState("Reject");
  const [details, setDetails] = useState(null);
  const [showAllAssets, setShowAllAssets] = useState(false);
  const dropdownRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const popover = (
    <Popover id="popover-basic-comming">
      <Popover.Body className="comming-tool-body">
        <label className="comming-text-tool">
          This issue was not resolved  after the latest scan.
        </label>
      </Popover.Body>
    </Popover>
  );
  const statusMap = {
    1: { text: "Open", icon: <OpenCircle /> },
    2: { text: "Re-Opened", icon: <ReOpened /> },
    3: { text: "Pending Validation", icon: <PendingValidation /> },
    4: {
      text: "Validation Failed",
      icon: (
        <>
          {" "}
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popover}
            className="mx-3"
          >
            <InfoCircle className="cursor-pointer" />
          </OverlayTrigger>
        </>
      ),
    },
    5: { text: "Solved", icon: <Solved /> },
    6: { text: "False-Positive", icon: <FalsePositive /> },
    7: { text: "Acknowledged", icon: <Acknowledged /> },
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const checked = e.target.checked;
    setIsChecked(checked);
    onSelectionChange(data, checked);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const mainClass = `securityissueCard_main${
    classNameVariant ? `_${classNameVariant}` : ""
  }`;
  const valueClass = `securityissueCard_section_3_value${
    classNameVariant ? `_${classNameVariant}` : ""
  }`;

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAction(option);
    setShowDropDown(false);
    setShowModal(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);
  useEffect(() => {
    setIsChecked(false);
  }, [clearSelectedRows]);

  let hasGenericData = false;
  data?.assets?.map((el) => {
    if (el?.generic_data1 && el?.generic_data1?.pages?.length > 0) {
      hasGenericData = true;
    }
  });
  let issuesFor = "";
  if (hasGenericData) {
    if (data?.service_id == 15) {
      issuesFor = "login";
    } else if (data?.service_id == 5) {
      issuesFor = "certificates";
    }
  }
  const source = isGroup ? asset : data;

  const handleActionSelect = (action) => {
    handleActionClick(action.name, isGroup ? asset : data, 0);
    setActivePopover(null);
  };
  const actionPopover = (id) => (
    <Popover id={`action-single-${id}`} className="p-0">
      <Popover.Body className="p-0">
        <ActionPopover
          rowId={id}
          actions={actions}
          onApply={(rowId, selectedAction) =>
            handleActionSelect(selectedAction)
          }
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <React.Fragment>
      <div
        className="SecurityIssueCard_Main cursor-pointer"
        onClick={() => {
          setDetails(data);
          handleShow();
        }}
      >
        <div className="">
          <div className="issue-check-box" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              className="security-checkbox me-1 "
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div
          className={`w-100 securityissuemain ${
            isChecked ? "border-action" : ""
          }`}
        >
          <div className={mainClass}>
            <div className="securityissueCard_section_1">
              <div className="section_1_part1">
                {!isGroup && (
                  <div className="securityissueCard_title">
                    {data?.issue_name}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center w-10 py-3">
                <div className="d-flex align-items-center">
                  <div className="section_1_part2 mt-0 pe-2">Asset:</div>
                  {source.domain && (
                    <div className="d-flex align-items-center text-unwrap assets-align">
                      {source.domain}
                    </div>
                  )}
                  {source.ip && (
                    <div className="d-flex align-items-center text-unwrap text-white">
                      {source.domain && <span className="px-1">|</span>}
                      {source.ip}
                    </div>
                  )}

                  {source.port && (
                    <div className="d-flex align-items-center text-unwrap text-white">
                      {(source.domain || source.ip) && (
                        <span className="px-1">|</span>
                      )}
                      Port: {source.port} :
                    </div>
                  )}
                  {source.host && (
                    <div className="d-flex align-items-center text-unwrap ">
                      {(source.domain || source.ip || source.port) && (
                        <span className="px-1">|</span>
                      )}
                      <AssetsHost source={source} />
                    </div>
                  )}
                </div>
                <div className="d-flex w-100 flex-wrap">
                  <div className="d-flex align-items-center issue-status-type text-unwrap">
                    {source.status && statusMap[source.status] && (
                      <div className=" mt-0 ms-2 pe-1 title-text text-unwrap text-white d-flex align-items-center">
                        <span className="me-1 d-flex">
                          {" "}
                          {statusMap[source.status].icon}
                        </span>
                        {statusMap[source.status].text}
                      </div>
                    )}
                  </div>
                  {!isGroup && (
                    <div className="d-flex align-items-center">
                      <div className="title-text mt-0 ps-1 pe-2 text-white">
                        , CVE:
                      </div>
                      <div className="cve-tag d-flex align-items-center justify-content-center">
                        <span>{data?.cve ? "Yes" : "No"}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="section-seen-days d-flex align-items-center">
                <div className="first-seen ">
                  <span className="title-text">First Seen:</span>
                  <span className="data-value-text ms-2">
                    <Moment format="MMM DD, YY" className="pe-2 text-white">
                      {source?.created_at}
                    </Moment>
                  </span>
                  {type === "history" && (
                    <>
                      <span className="title-text">Last Seen:</span>
                      <span className="data-value-text ms-2">
                        <Moment format="MMM DD, YY" className="pe-2 text-white">
                          {source?.last_seen_at}
                        </Moment>
                      </span>
                    </>
                  )}
                </div>
                <span className="title-text mx-2">|</span>
                <div className="">
                  {type === "open" ? (
                    <span className="title-text">Days Open:</span>
                  ) : (
                    <span className="title-text">Days to Remediation:</span>
                  )}
                  <span className="data-value-text ms-2 text-white">
                    {" "}
                    {source?.days_open}
                  </span>
                </div>
              </div>
            </div>
            <div className="securityissueCard_section_2 d-flex align-items-center">
              <div className="group-categroy-tags">
                <div className="d-flex align-items-center flex-wrap">
                  <CriticalityTag level={criticalityLevel} />
                  {!isGroup && (
                    <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                      <span className=" text-unwrap">
                        Category: {data?.scan_category}
                      </span>
                    </div>
                  )}
                  <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                    <span className=" text-unwrap">Impact: {data?.impact}</span>
                  </div>
                  <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                    <span className=" text-unwrap">
                      Exploitability: {data?.exploitability}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2 tag-m-0">
                  {data?.cve && (
                    <>
                      <div className="issues-assets-tag d-flex align-items-center">
                        <span className="ms-1">CVSS: {data?.cvss}</span>
                      </div>
                      <div className="issues-assets-tag d-flex align-items-center mx-2">
                        <span className="ms-1">EPSS: {data?.epss}</span>
                      </div>
                      <div className="issues-assets-tag d-flex align-items-center">
                        <span className="ms-1">
                          CISA KEV:{" "}
                          {data?.cisa_kev
                            ? data?.cisa_kev.charAt(0).toUpperCase() +
                              data?.cisa_kev.slice(1)
                            : ""}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="securityissue-section_3 d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column" >
                <div className="securityissueCard_section_3 me-1 d-flex flex-row">
                  <div className="securityissueCard_section_3_text text-nowrap pe-2">
                    Risk Score:
                  </div>
                  <div className={valueClass}>{data?.risk_score}</div>
                  <div className="mx-4">
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      overlay={actionPopover(data?.id)} // Pass unique ID for the action popover
                      show={activePopover === data?.id}
                      onToggle={() =>
                        setActivePopover(
                          activePopover === data?.id ? null : data?.id
                        )
                      }
                      rootClose
                    >
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Elipses className="cves-popup cursor-pointer " />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="section_1_part1 d-flex align-items-center justify-content-left mt-2">
                  <div className="securityissueCard_title"></div>
                  {data?.armory_tested && !isGroup ? (
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                      <img className="h-100" src={armorytestedIconSmall} />
                      <div className="securityissueCard_subtitle">
                        ARMORY TESTED
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CvesPopup
        handleClose={handleClose}
        show={show}
        details={details}
        isGroup={isGroup}
        asset={asset}
        criticality={criticalityLevel}
      />
    </React.Fragment>
  );
};

export default SecurityIssueCard;
