export const categories = [
  {
    id: 1,
    categ_name: "Network Security",
    categ_order: 1,
  },
  {
    id: 2,
    categ_name: "Servers and Hosts",
    categ_order: 2,
  },
  {
    id: 3,
    categ_name: "Web Applications",
    categ_order: 3,
  },
  {
    id: 4,
    categ_name: "Data Exposure",
    categ_order: 4,
  },
];

export const services = [
  {
    id: 3,
    service_name: "ports",
    key: "/scanner/:target_id/ports",
    title: "Ports, Services & Protocols",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 1,
    status: null,
    is_new: 0,
  },
  {
    id: 16,
    service_name: "default_credential",
    key: "/scanner/:target_id/ssh-credential",
    title: "SSH Authentication Security",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 1,
    status: null,
    is_new: 0,
  },
  {
    id: 17,
    service_name: "ftp_credential",
    key: "/scanner/:target_id/ftp-credential",
    title: "FTP Authentication Security",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 1,
    status: null,
    is_new: 0,
  },
  {
    id: 6,
    service_name: "email_security",
    key: "/scanner/:target_id/email-security",
    title: "Email Security",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 1,
    status: null,
    is_new: 0,
  },
  {
    id: 5,
    service_name: "certificates",
    key: "/scanner/:target_id/certificates",
    title: "Certificates",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 1,
    status: null,
    is_new: 0,
  },
  {
    id: 13,
    service_name: "os",
    key: "/scanner/:target_id/os",
    title: "OS and EndOflife Indication",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 2,
    status: null,
    is_new: 0,
  },
  {
    id: 7,
    service_name: "hosting_provider",
    key: "/scanner/:target_id/hosting-provider",
    title: "Hosting Provider",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 2,
    status: null,
    is_new: 0,
  },
  {
    id: 10,
    service_name: "dns",
    key: "/scanner/:target_id/dns",
    title: "DNS",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 2,
    status: null,
    is_new: 0,
  },
  {
    id: 12,
    service_name: "shodan",
    key: "/scanner/:target_id/shodan",
    title: "Shodan",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 2,
    status: null,
    is_new: 0,
  },
  {
    id: 21,
    service_name: "login_pages_security",
    key: "/scanner/:target_id/login-pages-security",
    title: "Login Pages Security",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 15,
    service_name: "login_pages",
    key: "/scanner/:target_id/login-page",
    title: "Login Pages",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 0,
    service_name: "headers_and_cookies",
    key: "/scanner/:target_id/headers-and-cookies",
    title: "Headers and Cookies",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 6,
    service_name: "3rd_party_companies",
    key: "/scanner/:target_id/3rd-party-companies",
    title: "3rd Party components",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 8,
    service_name: "waf",
    key: "/scanner/:target_id/waf",
    title: "WAF",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 9,
    service_name: "cms",
    key: "/scanner/:target_id/cms",
    title: "CMS",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 9,
    service_name: "cdn",
    key: "/scanner/:target_id/cdn",
    title: "CDN",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 3,
    status: null,
    is_new: 0,
  },
  {
    id: 19,
    service_name: "data_leaks",
    key: "/scanner/:target_id/data-leaks",
    title: "Data Leaks",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  {
    id: 20,
    service_name: "sensitive_exposed_data",
    key: "/scanner/:target_id/sensitive-exposed-data",
    title: "Sensitive Exposed Data",
    show_in_menu: 1,
    menu_enabled: 1,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  {
    id: 12,
    service_name: "exposed_documents",
    key: "/scanner/:target_id/exposed-documents",
    title: "Exposed Documents",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  // {
  //     id: 16,
  //     service_name: 'cloud_exposed_folders',
  //     key: '/scanner/:target_id/cloud-exposed-folders',
  //     title: 'Cloud exposed Folders',
  //     show_in_menu: 1,
  //     menu_enabled: 0,
  //     category_id: 4,
  //     status: null,
  //     is_new: 0
  // },
  {
    id: 17,
    service_name: "github_exposure",
    key: "/scanner/:target_id/github-exposure",
    title: "Github Exposure",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  {
    id: 21,
    service_name: "pasting_exposure",
    key: "/scanner/:target_id/pasting-exposure",
    title: "Pasting Exposure",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  {
    id: 22,
    service_name: "leaked_credentials",
    key: "/scanner/:target_id/leaked-credentials",
    title: "Leaked Credentials",
    show_in_menu: 1,
    menu_enabled: 0,
    category_id: 4,
    status: null,
    is_new: 0,
  },
  {
    id: 18,
    service_name: "subdomain_takeover",
    key: null,
    title: "Subdomain Takeover",
    show_in_menu: 0,
    menu_enabled: 0,
    category_id: 4,
    status: null,
    is_new: 0,
  },
];
