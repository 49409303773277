import React, { useEffect, useState, useRef } from "react";
import BaseTable from "../../components/table/BaseTable";
import { locationToPageName } from "../../helpers";
import { sendEvent } from "../../util/analytics";
import { useLocation } from "react-router-dom";
import { PreviewModal } from "./PreviewModal";
import { ReactComponent as Attachment } from "../../assets/images/attachment-icon.svg";
import { ReactComponent as Spinner } from "../../assets/images/spinner.svg";
import { showToast } from "../../util/toasts";
import { useParams } from "react-router-dom";
import axios from "../../util/axios";

const exampleData = [
  {
    root_domain: "someapp.com",
    Hostnames: "app.someapp.com",
    hostname_criticality: "3",
    IP: "167.12.59.2",
  },
  {
    root_domain: "testsite.com",
    Hostnames: "app.testsite.com",
    hostname_criticality: "3",
    IP: "198.51.100.10- 198.51.100.50",
  },
  {
    root_domain: "demo.com",
    Hostnames: "admin.demo.com",
    hostname_criticality: null,
    IP: "192.0.2.0/24",
  },
  {
    root_domain: "mywebsite.net",
    Hostnames: null,
    hostname_criticality: null,
    IP: "203.0.113.150",
  },
];

const columns = [
  { Header: "Root Domain", accessor: "root_domain", isSortable: false },
  { Header: "Hostname", accessor: "Hostnames", isSortable: false },
  {
    Header: "Hostname Critically",
    accessor: "hostname_criticality",
    isSortable: false,
  },
  { Header: "IP Address", accessor: "IP", isSortable: false },
];

const exportHeaders = [
  "Root Domain",
  "Hostname",
  "Hostname Critically",
  "IP Address",
];

const FileUpload = ({ setFileRecord }) => {
  const [file, setFile] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const location = useLocation();
  const exportRows = exampleData.map((item) => [
    item?.root_domain,
    item?.Hostnames,
    item?.hostname_criticality,
    item?.IP,
  ]);

  const sendExportEvent = (format) => {
    sendEvent("export", {
      pageName: locationToPageName(location),
      format,
    });
  };

  const exportCSV = () => {
    sendExportEvent("CSV");
    const header = exportHeaders;
    const rows = exportRows;
    const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(blob));
    link.setAttribute("download", `Example_Assets_File.csv`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const processFile = async (file) => {
    if (file && file.type === "text/csv" && file.size <= 52428800) {
      setFile(file);
      setIsUploading(true);
      try {
        setIsChanged(false);
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await axios.post(`targets/validate-file`, formData);
        if (data?.isValid) {
          setFileRecord(data.records);
          setPreviewData(data.records);
        } else {
          showToast("The file is not valid", "error", "top-center", "light");
        }
        setTimeout(() => {
          setIsUploading(false);
        }, 1000);
      } catch (err) {
        showToast(err?.response?.data?.message, "error", "top-center", "light");
        setTimeout(() => {
          setIsUploading(false);
        }, 1000);
        setIsChanged(false);
        setFile(null);
      }
    } else {
      showToast(
        "Only CSV files under 50MB are allowed.",
        "error",
        "top-center",
        "light"
      );
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setIsChanged(false);
    setFileRecord([]);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleViewFile = (file) => {
    setShowPreview(true);
  };

  const handleFileIsChange = (e) => {
    e.stopPropagation();
    setIsChanged(false);
  };

  return (
    <div style={{ margin: "20px 0px" }}>
      <div className="flex  align-items-center gap-4 file-heading my-3">
        <div>Example CSV File Format:</div>
        <div
          className="link"
          onClick={() => {
            exportCSV();
          }}
        >
          Download Example File
        </div>
      </div>
      <div>
        <ul>
          <li>Providing criticality values for each asset is optional.</li>
          <li>Criticality Levels: 1 for Low, 2 for Medium, 3 for High.</li>
          <li>
            If no values are specified, Armory will automatically assign a
            default <b className="file-heading">Medium</b> criticality level (2)
            to all assets.
          </li>
          <li>The table must include at least one of these column:</li>
        </ul>
      </div>
      <div className="table-section">
        <BaseTable
          className="h-fit-content"
          columns={columns}
          data={exampleData}
          selectable={false}
          showCheckboxes={false}
          isPagination={false}
        />
      </div>
      <div>
        <div className="file-heading mt-3">{"Upload CSV File"}</div>
        <>
          {file && !isChanged ? (
            <div>
              {isUploading ? (
                <div className="upload-container uploading">
                  <Spinner className="spinner-loader" />
                  Loading File...
                </div>
              ) : (
                <div className="flex align-items-center gap-2 mt-2">
                  <Attachment />
                  {file.name}

                  <div
                    className="button-link"
                    onClick={() => handleViewFile(file)}
                  >
                    View File
                  </div>
                  <div
                    className="button-link"
                    onClick={() => setIsChanged(true)}
                  >
                    Change File
                  </div>
                  <div className="button-link" onClick={handleRemoveFile}>
                    Remove File
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`upload-container ${dragActive ? "drag-active" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleClick}
            >
              <label className="cursor-pointer">
                <div className="file-heading cursor-pointer">Upload File</div>
                {!isChanged ? (
                  <div className="cursor-pointer">
                    You can click here to add or drop a file
                  </div>
                ) : (
                  <div className="cursor-pointer">
                    You can click here to add or drop a file to replace{" "}
                    <b>{file.name}</b>{" "}
                    <span
                      style={{
                        color: "#398FFE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleFileIsChange}
                    >
                      Cancel
                    </span>
                  </div>
                )}
              </label>
              <input
                ref={fileInputRef}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept=".csv"
              />
            </div>
          )}
        </>
      </div>
      <PreviewModal
        data={previewData}
        headings={columns}
        showModal={showPreview}
        hideModal={() => {
          setShowPreview(false);
        }}
      />
    </div>
  );
};

export default FileUpload;
